import shopifyGenericDefaults from '../shopify-generic/config.js';
import ConvermaxProductBlock from './productBlock.js';

const InitFunc = () => {
  shopifyGenericDefaults.InitFunc?.();
  window.customElements.define('convermax-product-block', ConvermaxProductBlock);
};

export default {
  includes: ['shopify-generic'],
  ...shopifyGenericDefaults,
  InitFunc,
  Widgets: [
    ...shopifyGenericDefaults.Widgets.filter((w) => !['HeaderVehicleWidget', 'SearchBox'].includes(w.name)),
    {
      name: 'HeaderVehicleWidget',
      type: 'VehicleWidget',
      location: {
        selector: '#cm-ymm',
        class: 'cm_vehicle-widget__header-container-desktop navigation__link',
      },
      template: 'fitmentSearch/collapsibleVehicleWidget',
      isAlwaysColumnLayout: true,
      initCollapsed: true,
    },
    {
      name: 'HeaderVehicleWidget_mobile',
      type: 'VehicleWidget',
      location: {
        selector: '#cm-ymm__custom-header__mobile',
        class: 'cm_vehicle-widget__header-container-mobile',
      },
      template: 'fitmentSearch/collapsibleVehicleWidget',
      isAlwaysColumnLayout: true,
      initCollapsed: true,
    },
    {
      name: 'SearchBox',
      type: 'SearchBoxDialogButton',
      location: { selector: '#cm-search-box', class: 'cm-search-box' },
      template: 'searchBox/dialogButtonAdaptable',
      onSubmit: () =>
        window.location.pathname === '/pages/search'
          ? document.querySelector('main-search .main-search__close')?.click()
          : null,
    },
  ],
};

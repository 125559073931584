
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function repeatSwatchesImage1(swatchesImage, swatchesImageIndex) {
                    return _createElement('div', {
                        'className': 'product-block__image product-block__image--secondary',
                        'data-media-id': swatchesImage.split('|')[2].split('?variant=')[1],
                        'key': 'image-' + swatchesImage.split('|')[2].split('?variant=')[1]
                    }, _createElement('div', {
                        'className': 'img-ar img-ar--cover',
                        'style': { aspectRatio: '1' }
                    }, _createElement('img', {
                        'src': swatchesImage.split('|')[1],
                        'width': '700',
                        'height': '700',
                        'loading': 'lazy',
                        'className': 'theme-img'
                    })));
                }
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                function repeatColorOption3(colorOption, colorOptionIndex) {
                    return _createElement('span', {
                        'className': 'product-block-options__item',
                        'data-option-item': colorOption.split('|')[0],
                        'data-media': colorOption.split('|')[2].split('?variant=')[1],
                        'data-swatch': colorOption.split('|')[0].toLowerCase(),
                        'key': 'color-' + colorOption.split('|')[0].toLowerCase()
                    }, _createElement('span', { 'className': 'product-block-options__item__text' }, colorOption.split('|')[0]));
                }
                function repeatColorOption4(colorOption, colorOptionIndex) {
                    return _createElement('span', {
                        'className': 'product-block-options__item product-block-options__item--truncated',
                        'data-option-item': colorOption.split('|')[0],
                        'data-media': colorOption.split('|')[2].split('?variant=')[1],
                        'data-swatch': colorOption.split('|')[0].toLowerCase(),
                        'key': 'color-' + colorOption.split('|')[0].toLowerCase()
                    }, _createElement('span', { 'className': 'product-block-options__item__text' }, colorOption.split('|')[0]));
                }
                return _createElement('convermax-product-block', {
                    'data-product-id': this.id,
                    'class': 'product-block'
                }, _createElement('div', { 'className': 'block-inner' }, _createElement('div', { 'className': 'block-inner-inner' }, _createElement('div', { 'className': 'image-cont image-cont--with-secondary-image image-cont--same-aspect-ratio' }, _createElement('a', {
                    'className': 'product-link',
                    'href': this.url,
                    'aria-hidden': 'true',
                    'tabIndex': '-1'
                }, _createElement('div', { 'className': 'image-label-wrap' }, _createElement('div', {}, !this.product_colors || this.product_colors.filter(pc => pc.split('|')[1]).length === 0 ? [
                    _createElement('div', {
                        'className': 'product-block__image ' + (this.image2 ? ' product-block__image--primary product-block__image--active' : ''),
                        'key': '3911'
                    }, _createElement('div', {
                        'className': 'img-ar img-ar--cover',
                        'style': { aspectRatio: '1' }
                    }, _createElement('img', {
                        'src': this.imageOrDefault(this.image),
                        'width': '700',
                        'height': '700',
                        'loading': 'lazy',
                        'className': 'theme-img'
                    }))),
                    this.image2 ? _createElement('div', {
                        'className': 'product-block__image product-block__image--secondary product-block__image--show-on-hover',
                        'key': '3913'
                    }, _createElement('div', {
                        'className': 'img-ar img-ar--cover',
                        'style': { aspectRatio: '1' }
                    }, _createElement('img', {
                        'src': this.imageOrDefault(this.image2),
                        'width': '700',
                        'height': '700',
                        'loading': 'lazy',
                        'className': 'theme-img'
                    }))) : null
                ] : null, this.product_colors && this.product_colors.filter(pc => pc.split('|')[1]).length !== 0 ? [
                    _createElement('div', {
                        'className': 'product-block__image product-block__image--primary product-block__image--active',
                        'data-media-id': this.product_colors.filter(pc => pc.split('|')[1])[0].split('|')[2].split('?variant=')[1],
                        'key': '13531'
                    }, _createElement('div', {
                        'className': 'img-ar img-ar--cover',
                        'style': { aspectRatio: '1' }
                    }, _createElement('img', {
                        'src': this.product_colors.filter(pc => pc.split('|')[1])[0].split('|')[1],
                        'width': '700',
                        'height': '700',
                        'loading': 'lazy',
                        'className': 'theme-img'
                    }))),
                    this.product_colors.filter(pc => pc.split('|')[1])[1] ? _createElement('div', {
                        'className': 'product-block__image product-block__image--secondary product-block__image--show-on-hover',
                        'data-media-id': this.product_colors.filter(pc => pc.split('|')[1])[1].split('|')[2].split('?variant=')[1],
                        'key': '13533'
                    }, _createElement('div', {
                        'className': 'img-ar img-ar--cover',
                        'style': { aspectRatio: '1' }
                    }, _createElement('img', {
                        'src': this.product_colors.filter(pc => pc.split('|')[1])[1].split('|')[1],
                        'width': '700',
                        'height': '700',
                        'loading': 'lazy',
                        'className': 'theme-img'
                    }))) : null,
                    this.product_colors.filter(pc => pc.split('|')[1]).length > 2 ? _map(this.product_colors.filter(pc => pc.split('|')[1]).slice(2), repeatSwatchesImage1.bind(this)) : null
                ] : null, _createElement('div', { 'className': 'product-label-container' }, this.theme_label ? _createElement('span', {
                    'className': 'product-label product-label--meta',
                    'key': '3487'
                }, _createElement('span', {}, this.theme_label)) : null, this.category && this.category.includes('New Products') ? _createElement('span', {
                    'className': 'product-label product-label--new-in',
                    'key': '3651'
                }, _createElement('span', {}, 'New arrival')) : null)))), _createElement('a', {
                    'className': 'image-page-button image-page-button--previous has-ltr-icon',
                    'href': '#',
                    'aria-label': 'Previous',
                    'tabIndex': '-1'
                }, _createElement('svg', {
                    'width': '24',
                    'height': '24',
                    'viewBox': '0 0 24 24',
                    'fill': 'none',
                    'stroke': 'currentColor',
                    'strokeWidth': '1.3',
                    'strokeLinecap': 'round',
                    'strokeLinejoin': 'round',
                    'className': 'icon feather feather-chevron-left',
                    'aria-hidden': 'true',
                    'focusable': 'false',
                    'role': 'presentation'
                }, _createElement('path', { 'd': 'm15 18-6-6 6-6' }))), _createElement('a', {
                    'className': 'image-page-button image-page-button--next has-ltr-icon',
                    'href': '#',
                    'aria-label': 'Next',
                    'tabIndex': '-1'
                }, _createElement('svg', {
                    'width': '24',
                    'height': '24',
                    'viewBox': '0 0 24 24',
                    'fill': 'none',
                    'stroke': 'currentColor',
                    'strokeWidth': '1.3',
                    'strokeLinecap': 'round',
                    'strokeLinejoin': 'round',
                    'className': 'icon feather feather-chevron-right',
                    'aria-hidden': 'true',
                    'focusable': 'false',
                    'role': 'presentation'
                }, _createElement('path', { 'd': 'm9 18 6-6-6-6' }))), _createElement('a', {
                    'className': 'btn btn--secondary quickbuy-toggle',
                    'href': this.url
                }, 'Quick buy')), _createElement('div', { 'className': 'product-block__detail align-ltr-left' }, _createElement('div', { 'className': 'inner' }, _createElement('div', { 'className': 'innerer' }, _createElement('a', {
                    'className': 'product-link',
                    'href': this.url
                }, _createElement('div', mergeProps({ 'className': 'product-block__title' }, { dangerouslySetInnerHTML: { __html: this.title } })), _createElement('div', { 'className': 'product-price product-price--block' }, _createElement('div', { 'className': 'price' }, _createElement('div', { 'className': 'price__default' }, this.price_varies ? _createElement('span', {
                    'className': 'price__from',
                    'key': '5346'
                }, 'From: ') : null, _createElement('span', { 'className': 'price__current' }, ' ', this.formatPrice(this.price), ' '), this.on_sale ? _createElement('span', {
                    'className': 'price__was',
                    'key': '5521'
                }, '\n                      ', this.formatPrice(this.compare_at_price), '\n                    ') : null)))), _createElement('div', {
                    'className': 'product-block-options product-block-options--swatch',
                    'data-option-name': 'Color'
                }, _createElement('div', { 'className': 'product-block-options__inner' }, this.product_colors ? [
                    _map(this.product_colors.slice(0, 3), repeatColorOption3.bind(this)),
                    _map(this.product_colors.slice(3), repeatColorOption4.bind(this)),
                    this.product_colors.length > 3 ? _createElement('span', {
                        'className': 'product-block-options__more-label',
                        'key': '59223'
                    }, '\n                    + ', this.product_colors.length - 3, ' more\n                  ') : null
                ] : null))))))), _createElement('div', { 'className': 'quickbuy-container use-color-scheme use-color-scheme--1' }, _createElement('a', {
                    'href': '#',
                    'className': 'close-detail',
                    'aria-label': 'Close',
                    'tabIndex': '-1'
                }, _createElement('svg', {
                    'width': '24',
                    'height': '24',
                    'viewBox': '0 0 24 24',
                    'fill': 'none',
                    'stroke': 'currentColor',
                    'strokeWidth': '1',
                    'strokeLinecap': 'round',
                    'strokeLinejoin': 'round',
                    'className': 'icon feather feather-x',
                    'aria-hidden': 'true',
                    'focusable': 'false',
                    'role': 'presentation'
                }, _createElement('path', { 'd': 'M18 6 6 18M6 6l12 12' }))), _createElement('div', { 'className': 'inner' })));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You might also like...')), _createElement.apply(this, [
        'div',
        { 'className': 'cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []
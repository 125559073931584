const ConvermaxProductBlock = class extends window.HTMLElement {
  constructor() {
    super();
    setTimeout(() => {
      window.initLazyScript(this, this.init.bind(this));
    }, 1);
  }

  init() {
    this.images = Array.from(this.querySelectorAll('.product-block__image'));
    if (this.images.length > 1) {
      this.initImagePagination();
      this.monitorSwatchSelection();
      this.initCustomLazyLoading();
    }
  }

  initCustomLazyLoading() {
    // preload hover images after a delay
    setTimeout(() => {
      this.querySelector('.product-block__image--show-on-hover').classList.remove(
        'product-block__image--inactivated',
      );
    }, 1000);
    // activate all images after first interaction
    this.boundLazyLoadAll = this.lazyLoadAll.bind(this);
    this.addEventListener('mouseenter', this.boundLazyLoadAll);
    this.addEventListener('touchstart', this.boundLazyLoadAll, { passive: true });
  }

  lazyLoadAll() {
    this.querySelectorAll('.product-block__image--inactivated').forEach((el) =>
      el.classList.remove('product-block__image--inactivated'),
    );
    this.removeEventListener('mouseenter', this.boundLazyLoadAll);
    this.removeEventListener('touchstart', this.boundLazyLoadAll);
  }

  // get image at index, safely wrapping index
  getImageAt(index) {
    // positive modulo, like it should be, JS does not do *maths* correctly
    return this.images[((index % this.images.length) + this.images.length) % this.images.length];
  }

  incrementActiveImage(increment) {
    let index = 0;
    for (let i = 0; i < this.images.length; i += 1) {
      if (this.images[i].classList.contains('product-block__image--active')) {
        index = i;
        break;
      }
    }
    this.setActiveImage(index + increment);
  }

  setActiveImage(index) {
    const newActiveImage = this.getImageAt(index);
    const hoverImage = this.getImageAt(index + 1);

    // set new active image visibility
    [...newActiveImage.parentElement.children].forEach((el) => {
      el.classList.toggle('product-block__image--active', el === newActiveImage);
      el.classList.toggle('product-block__image--show-on-hover', el === hoverImage);
    });

    // dots
    this.querySelectorAll('.product-block__image-dot').forEach((el, iter) => {
      el.classList.toggle('product-block__image-dot--active', iter === index);
    });
  }

  initImagePagination() {
    // next button
    this.querySelector('.image-page-button--next').addEventListener('click', (evt) => {
      evt.preventDefault();
      this.incrementActiveImage(1);
    });

    // previous button
    this.querySelector('.image-page-button--previous').addEventListener('click', (evt) => {
      evt.preventDefault();
      this.incrementActiveImage(-1);
    });

    // swipe (when not in a carousel)
    if (!this.closest('.carousel, .product-grid--scrollarea')) {
      const touchContainer = this.querySelector('.image-cont--with-secondary-image');
      touchContainer.addEventListener(
        'touchstart',
        (evt) => {
          // swipe may end in another block
          window.theme.productBlockTouchTracking = true;
          window.theme.productBlockTouchStartX = evt.touches[0].clientX;
          window.theme.productBlockTouchStartY = evt.touches[0].clientY;
        },
        { passive: true },
      );

      touchContainer.addEventListener(
        'touchmove',
        (evt) => {
          if (window.theme.productBlockTouchTracking) {
            if (Math.abs(evt.touches[0].clientY - window.theme.productBlockTouchStartY) < 30) {
              const deltaX = evt.touches[0].clientX - window.theme.productBlockTouchStartX;
              if (deltaX > 25) {
                this.incrementActiveImage(-1);
                window.theme.productBlockTouchTracking = false;
              } else if (deltaX < -25) {
                this.incrementActiveImage(1);
                window.theme.productBlockTouchTracking = false;
              }
            }
          }
        },
        { passive: true },
      );

      touchContainer.addEventListener('touchend', () => {
        window.theme.productBlockTouchTracking = false;
      });
    }
  }

  onSelectSwatch(evt) {
    evt.preventDefault();
    let index = -1;

    for (let i = 0; i < this.images.length; i += 1) {
      if (this.images[i].dataset.mediaId === evt.currentTarget.dataset.media) {
        index = i;
        break;
      }
    }

    if (index < 0) {
      return;
    }

    this.setActiveImage(index);

    // include in URL
    const { optionName } = evt.currentTarget.closest('.product-block-options').dataset;
    const optionValue = evt.currentTarget.dataset.optionItem;
    this.querySelectorAll('.product-link, .quickbuy-toggle').forEach((el) => {
      const url = new URL(el.href);
      const params = new URLSearchParams(url.search);
      params.set(optionName, optionValue);
      el.href = `${url.pathname}?${params}`;
      el.rel = 'nofollow';
    });
  }

  monitorSwatchSelection() {
    this.querySelectorAll('[data-media].product-block-options__item').forEach((el) => {
      el.addEventListener('mouseenter', this.onSelectSwatch.bind(this));
      el.addEventListener('click', this.onSelectSwatch.bind(this));
    });
  }
};

export default ConvermaxProductBlock;

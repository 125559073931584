
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function SearchBoxDialogRT () {
    return _createElement('div', { 'className': 'cm_search-box-root__dialog' }, _createElement('div', { 'className': 'clear-self cm_search-box_container' }, _createElement('div', { 'className': 'dialog-container' }, _createElement('div', {
        'className': 'dialog-content',
        'onSubmit': this.preventFormSubmission
    }, _createElement('div', { 'className': 'dialog-header' }, _createElement('div', { 'className': 'dialog-title' }, 'Search'), _createElement('div', {
        'className': 'cm_search-box-dialog_close-button close',
        'onClick': this.close
    }, _createElement('svg', {
        'className': 'cm_icon cm_icon-times',
        'height': '20px',
        'role': 'img',
        'viewBox': '0 0 22 22'
    }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' })))), _createElement('div', { 'className': 'cm_search-box_form-container' }, [
        [this.searchInput(function () {
                return _createElement('div', { 'className': 'cm_searchInput' }, window.Convermax.config?.searchBoxPlaceholderText || 'Search');
            }, {
                widgetName: 'undefined',
                items: undefined
            })],
        _createElement('span', {
            'className': 'cm_search-box_clear-container',
            'key': '5812'
        }, this.inputNotEmpty ? _createElement('span', {
            'className': 'cm_search-box_clear',
            'onClick': this.clearInput,
            'key': '923'
        }, '\n    ', window.innerWidth > 768 ? '\u2715' : 'Clear', '\n  ') : null),
        _createElement('button', {
            'type': 'button',
            'title': 'Search',
            'aria-label': 'search button',
            'className': 'cm_search-box_submit cm_button cm_button__primary',
            'data-cm-role': 'add-query',
            'key': '5814'
        }, [_createElement('svg', {
                'className': 'icon',
                'width': '24',
                'height': '24',
                'viewBox': '0 0 24 24',
                'aria-hidden': 'true',
                'focusable': 'false',
                'role': 'presentation',
                'key': '12570'
            }, _createElement('g', {
                'transform': 'translate(3 3)',
                'stroke': 'currentColor',
                'strokeWidth': '1.5',
                'fill': 'none',
                'fillRule': 'evenodd'
            }, _createElement('circle', {
                'cx': '7.824',
                'cy': '7.824',
                'r': '7.824'
            }), _createElement('path', {
                'strokeLinecap': 'square',
                'd': 'm13.971 13.971 4.47 4.47'
            })))])
    ]), [this.dropdown(function () {
            function repeatSections1(sections, sectionsIndex) {
                return [sections(function () {
                        function repeatItems1(items, itemsIndex) {
                            return [items(function () {
                                    function mergeProps_text(inline, external) {
                                        var res = Object.assign({}, inline, external);
                                        if (inline.hasOwnProperty('style')) {
                                            res.style = _.defaults(res.style, inline.style);
                                        }
                                        if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                            res.className = external.className + ' ' + inline.className;
                                        }
                                        return res;
                                    }
                                    function repeatSwatchesImage1_product(swatchesImage, swatchesImageIndex) {
                                        return _createElement('div', {
                                            'className': 'product-block__image product-block__image--secondary',
                                            'data-media-id': swatchesImage.split('|')[2].split('?variant=')[1],
                                            'key': 'image-' + swatchesImage.split('|')[2].split('?variant=')[1]
                                        }, _createElement('div', {
                                            'className': 'img-ar img-ar--cover',
                                            'style': { aspectRatio: '1' }
                                        }, _createElement('img', {
                                            'src': swatchesImage.split('|')[1],
                                            'width': '700',
                                            'height': '700',
                                            'loading': 'lazy',
                                            'className': 'theme-img'
                                        })));
                                    }
                                    function mergeProps_product(inline, external) {
                                        var res = Object.assign({}, inline, external);
                                        if (inline.hasOwnProperty('style')) {
                                            res.style = _.defaults(res.style, inline.style);
                                        }
                                        if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                            res.className = external.className + ' ' + inline.className;
                                        }
                                        return res;
                                    }
                                    function repeatColorOption3_product(colorOption, colorOptionIndex) {
                                        return _createElement('span', {
                                            'className': 'product-block-options__item',
                                            'data-option-item': colorOption.split('|')[0],
                                            'data-media': colorOption.split('|')[2].split('?variant=')[1],
                                            'data-swatch': colorOption.split('|')[0].toLowerCase(),
                                            'key': 'color-' + colorOption.split('|')[0].toLowerCase()
                                        }, _createElement('span', { 'className': 'product-block-options__item__text' }, colorOption.split('|')[0]));
                                    }
                                    function repeatColorOption4_product(colorOption, colorOptionIndex) {
                                        return _createElement('span', {
                                            'className': 'product-block-options__item product-block-options__item--truncated',
                                            'data-option-item': colorOption.split('|')[0],
                                            'data-media': colorOption.split('|')[2].split('?variant=')[1],
                                            'data-swatch': colorOption.split('|')[0].toLowerCase(),
                                            'key': 'color-' + colorOption.split('|')[0].toLowerCase()
                                        }, _createElement('span', { 'className': 'product-block-options__item__text' }, colorOption.split('|')[0]));
                                    }
                                    function onError1_facetValue(e) {
                                        e.target.classList.add('cm_hide');
                                    }
                                    function mergeProps_facetValue(inline, external) {
                                        var res = Object.assign({}, inline, external);
                                        if (inline.hasOwnProperty('style')) {
                                            res.style = _.defaults(res.style, inline.style);
                                        }
                                        if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                            res.className = external.className + ' ' + inline.className;
                                        }
                                        return res;
                                    }
                                    return this.template === 'text' ? _createElement('div', mergeProps_text({ 'className': 'cmTemplate_text' }, { dangerouslySetInnerHTML: { __html: this.textHighlight } })) : this.template === 'product' ? _createElement('div', { 'className': 'cmTemplate_product' }, _createElement('convermax-product-block', {
                                        'data-product-id': this.id,
                                        'class': 'product-block'
                                    }, _createElement('div', { 'className': 'block-inner' }, _createElement('div', { 'className': 'block-inner-inner' }, _createElement('div', { 'className': 'image-cont image-cont--with-secondary-image image-cont--same-aspect-ratio' }, _createElement('a', {
                                        'className': 'product-link',
                                        'href': this.url,
                                        'aria-hidden': 'true',
                                        'tabIndex': '-1'
                                    }, _createElement('div', { 'className': 'image-label-wrap' }, _createElement('div', {}, !this.product_colors || this.product_colors.filter(pc => pc.split('|')[1]).length === 0 ? [
                                        _createElement('div', {
                                            'className': 'product-block__image ' + (this.image2 ? ' product-block__image--primary product-block__image--active' : ''),
                                            'key': '4231'
                                        }, _createElement('div', {
                                            'className': 'img-ar img-ar--cover',
                                            'style': { aspectRatio: '1' }
                                        }, _createElement('img', {
                                            'src': this.imageOrDefault(this.image),
                                            'width': '700',
                                            'height': '700',
                                            'loading': 'lazy',
                                            'className': 'theme-img'
                                        }))),
                                        this.image2 ? _createElement('div', {
                                            'className': 'product-block__image product-block__image--secondary product-block__image--show-on-hover',
                                            'key': '4233'
                                        }, _createElement('div', {
                                            'className': 'img-ar img-ar--cover',
                                            'style': { aspectRatio: '1' }
                                        }, _createElement('img', {
                                            'src': this.imageOrDefault(this.image2),
                                            'width': '700',
                                            'height': '700',
                                            'loading': 'lazy',
                                            'className': 'theme-img'
                                        }))) : null
                                    ] : null, this.product_colors && this.product_colors.filter(pc => pc.split('|')[1]).length !== 0 ? [
                                        _createElement('div', {
                                            'className': 'product-block__image product-block__image--primary product-block__image--active',
                                            'data-media-id': this.product_colors.filter(pc => pc.split('|')[1])[0].split('|')[2].split('?variant=')[1],
                                            'key': '13851'
                                        }, _createElement('div', {
                                            'className': 'img-ar img-ar--cover',
                                            'style': { aspectRatio: '1' }
                                        }, _createElement('img', {
                                            'src': this.product_colors.filter(pc => pc.split('|')[1])[0].split('|')[1],
                                            'width': '700',
                                            'height': '700',
                                            'loading': 'lazy',
                                            'className': 'theme-img'
                                        }))),
                                        this.product_colors.filter(pc => pc.split('|')[1])[1] ? _createElement('div', {
                                            'className': 'product-block__image product-block__image--secondary product-block__image--show-on-hover',
                                            'data-media-id': this.product_colors.filter(pc => pc.split('|')[1])[1].split('|')[2].split('?variant=')[1],
                                            'key': '13853'
                                        }, _createElement('div', {
                                            'className': 'img-ar img-ar--cover',
                                            'style': { aspectRatio: '1' }
                                        }, _createElement('img', {
                                            'src': this.product_colors.filter(pc => pc.split('|')[1])[1].split('|')[1],
                                            'width': '700',
                                            'height': '700',
                                            'loading': 'lazy',
                                            'className': 'theme-img'
                                        }))) : null,
                                        this.product_colors.filter(pc => pc.split('|')[1]).length > 2 ? _map(this.product_colors.filter(pc => pc.split('|')[1]).slice(2), repeatSwatchesImage1_product.bind(this)) : null
                                    ] : null, _createElement('div', { 'className': 'product-label-container' }, this.theme_label ? _createElement('span', {
                                        'className': 'product-label product-label--meta',
                                        'key': '3519'
                                    }, _createElement('span', {}, this.theme_label)) : null, this.category && this.category.includes('New Products') ? _createElement('span', {
                                        'className': 'product-label product-label--new-in',
                                        'key': '3683'
                                    }, _createElement('span', {}, 'New arrival')) : null)))), _createElement('a', {
                                        'className': 'image-page-button image-page-button--previous has-ltr-icon',
                                        'href': '#',
                                        'aria-label': 'Previous',
                                        'tabIndex': '-1'
                                    }, _createElement('svg', {
                                        'width': '24',
                                        'height': '24',
                                        'viewBox': '0 0 24 24',
                                        'fill': 'none',
                                        'stroke': 'currentColor',
                                        'strokeWidth': '1.3',
                                        'strokeLinecap': 'round',
                                        'strokeLinejoin': 'round',
                                        'className': 'icon feather feather-chevron-left',
                                        'aria-hidden': 'true',
                                        'focusable': 'false',
                                        'role': 'presentation'
                                    }, _createElement('path', { 'd': 'm15 18-6-6 6-6' }))), _createElement('a', {
                                        'className': 'image-page-button image-page-button--next has-ltr-icon',
                                        'href': '#',
                                        'aria-label': 'Next',
                                        'tabIndex': '-1'
                                    }, _createElement('svg', {
                                        'width': '24',
                                        'height': '24',
                                        'viewBox': '0 0 24 24',
                                        'fill': 'none',
                                        'stroke': 'currentColor',
                                        'strokeWidth': '1.3',
                                        'strokeLinecap': 'round',
                                        'strokeLinejoin': 'round',
                                        'className': 'icon feather feather-chevron-right',
                                        'aria-hidden': 'true',
                                        'focusable': 'false',
                                        'role': 'presentation'
                                    }, _createElement('path', { 'd': 'm9 18 6-6-6-6' }))), _createElement('a', {
                                        'className': 'btn btn--secondary quickbuy-toggle',
                                        'href': this.url
                                    }, 'Quick buy')), _createElement('div', { 'className': 'product-block__detail align-ltr-left' }, _createElement('div', { 'className': 'inner' }, _createElement('div', { 'className': 'innerer' }, _createElement('a', {
                                        'className': 'product-link',
                                        'href': this.url
                                    }, _createElement('div', mergeProps_product({ 'className': 'product-block__title' }, { dangerouslySetInnerHTML: { __html: this.title } })), _createElement('div', { 'className': 'product-price product-price--block' }, _createElement('div', { 'className': 'price' }, _createElement('div', { 'className': 'price__default' }, this.price_varies ? _createElement('span', {
                                        'className': 'price__from',
                                        'key': '5378'
                                    }, 'From: ') : null, _createElement('span', { 'className': 'price__current' }, ' ', this.formatPrice(this.price), ' '), this.on_sale ? _createElement('span', {
                                        'className': 'price__was',
                                        'key': '5553'
                                    }, '\n                      ', this.formatPrice(this.compare_at_price), '\n                    ') : null)))), _createElement('div', {
                                        'className': 'product-block-options product-block-options--swatch',
                                        'data-option-name': 'Color'
                                    }, _createElement('div', { 'className': 'product-block-options__inner' }, this.product_colors ? [
                                        _map(this.product_colors.slice(0, 3), repeatColorOption3_product.bind(this)),
                                        _map(this.product_colors.slice(3), repeatColorOption4_product.bind(this)),
                                        this.product_colors.length > 3 ? _createElement('span', {
                                            'className': 'product-block-options__more-label',
                                            'key': '59543'
                                        }, '\n                    + ', this.product_colors.length - 3, ' more\n                  ') : null
                                    ] : null))))))), _createElement('div', { 'className': 'quickbuy-container use-color-scheme use-color-scheme--1' }, _createElement('a', {
                                        'href': '#',
                                        'className': 'close-detail',
                                        'aria-label': 'Close',
                                        'tabIndex': '-1'
                                    }, _createElement('svg', {
                                        'width': '24',
                                        'height': '24',
                                        'viewBox': '0 0 24 24',
                                        'fill': 'none',
                                        'stroke': 'currentColor',
                                        'strokeWidth': '1',
                                        'strokeLinecap': 'round',
                                        'strokeLinejoin': 'round',
                                        'className': 'icon feather feather-x',
                                        'aria-hidden': 'true',
                                        'focusable': 'false',
                                        'role': 'presentation'
                                    }, _createElement('path', { 'd': 'M18 6 6 18M6 6l12 12' }))), _createElement('div', { 'className': 'inner' })))) : this.template === 'facetValue' ? _createElement('div', { 'className': 'cmTemplate_facetValue' }, this.imageUrl ? _createElement('div', {
                                        'className': 'image-block',
                                        'key': '35'
                                    }, _createElement('img', {
                                        'src': this.imageUrl,
                                        'alt': 'Image of ' + this.facetValue,
                                        'onError': onError1_facetValue.bind(this)
                                    })) : null, _createElement('div', { 'className': 'description-block' }, _createElement('div', mergeProps_facetValue({ 'className': 'title' }, { dangerouslySetInnerHTML: { __html: this.value } })), this.hitCount ? _createElement('span', {
                                        'className': 'hit-count',
                                        'key': '335'
                                    }, '(', this.hitCount, ')') : null)) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                                }, { count: undefined })];
                        }
                        return _createElement('div', { 'className': 'section' + (this.sectionTitle ? ` ${ this.sectionTitle }` : '') + ' ' + (this.sectionTitle === 'Products' ? 'section_products' : 'section_text') }, this.sectionTitle !== 'Products' ? _createElement('span', {
                            'className': 'section-title-span',
                            'key': '197'
                        }, 'Suggestions: ') : null, _createElement.apply(this, [
                            'div',
                            { 'className': 'section' + (this.sectionTitle ? ` ${ this.sectionTitle }` : '') + ' ' + (this.sectionTitle === 'Products' ? 'product-grid product-grid--per-row-4 product-grid--per-row-mob-1 product-grid--per-row-mob-1' : '') + ' cmRepeater_items' },
                            _map(this.items, repeatItems1.bind(this))
                        ]));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'dialog-body cm_autocomplete cm_dropdown' }, _createElement.apply(this, [
                'div',
                { 'className': 'cmRepeater_sections' },
                _map(this.sections, repeatSections1.bind(this))
            ]), this.browseAllButton ? [[this.browseAllButton(function () {
                        return _createElement('a', { 'className': 'main-search__results-all-link btn btn--secondary cm_browseAllButton' }, 'Browse all ', this.totalHits, ' products');
                    }, {
                        widgetName: 'undefined',
                        items: undefined
                    })]] : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })]))));
}
        export const componentNames = ["cm:searchInput","cm:browseAllButton","cm:dropdown"]